<template>
  <div>
    <Hero
      title="Contact"
      :palette="['#4b79cf', '#5176cf']"
      :angle="120"
      :dark="true"
      :transparency="true"
      :gradientAnimation="false"
      :fillHeight="false"
      animationName="fade-in"
    />

    <v-container>
      <div class="my-9">
        <div class="text-h3 mb-6" data-aos="fade-in">
          We're available Mon – Fri, 9am – 5pm GMT+1
        </div>
      </div>

      <div class="text-body-1 my-6" data-aos="fade-in" data-aos-delay="100">
        Our Team is happy to answer any questions about Abardo and we will get back to you
        within one hour.
      </div>

      <div class="text-body-1 my-6" data-aos="fade-in" data-aos-delay="100">
        Please raise a ticket using the form below or email us at
        <a href="mailto:hello@abardo.com">hello@abardo.com</a>.
      </div>

      <div class="text-body-1 my-6" data-aos="fade-in" data-aos-delay="100"></div>

      <v-form
        v-model="validForm"
        lazy-validation
        ref="form"
        data-aos="fade-in"
        data-aos-delay="200"
        class="py-3"
      >
        <v-text-field
          outlined
          label="Name"
          v-model="name"
          :rules="[(v) => !!v || 'Required']"
          required
          ref="name"
        ></v-text-field>

        <v-text-field
          outlined
          label="Email"
          v-model="email"
          type="email"
          :rules="[(v) => !!v || 'Required', (v) => /.+@.+/.test(v) || 'Must be valid']"
          required
        ></v-text-field>

        <v-textarea
          outlined
          name="message"
          label="Message"
          v-model="message"
        ></v-textarea>

        <v-checkbox
          class="my-0"
          v-model="agreement"
          required
          :rules="[(v) => !!v || 'You must agree to continue!']"
        >
          <template v-slot:label>
            <div>
              I agree to the
              <a
                class="text-decoration-none"
                @click.stop
                target="_blank"
                href="https://abardo.com/docs/privacy-policy"
                >privacy policy</a
              >
              and
              <a
                class="text-decoration-none"
                @click.stop
                target="_blank"
                href="https://abardo.com/docs/terms-of-use"
                >terms and conditions</a
              >.
            </div>
          </template>
        </v-checkbox>

        <v-btn
          class="mt-3"
          :block="$vuetify.breakpoint.xsOnly"
          dark
          rounded
          elevation="0"
          x-large
          :loading="isSending"
          @click="validate"
          ><v-icon>mdi-send</v-icon>
          <div class="ml-1">Send</div></v-btn
        >
      </v-form>

      <v-snackbar v-model="snack.show" color="black">
        {{ snack.text }}
        <template v-slot:action="{ attrs }">
          <v-btn color="white" text v-bind="attrs" @click="snack.show = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>

      <!--

All published apps are required to provide a Support URL for individual on-going support for users of this app. 
Your support page must include the information outlined below to help customers understand what they can expect when engaging with your support team:

Hours of Your Support Team
First Response SLA (Maximum time a customer should expect to wait until they receive their first response from a member in your Customer Support Team)
Link to create a support case
Link to email support
Link to your Knowledge base or forums.
Link to a live customer support channel (if available)
Support Phone Number (if available)
      -->
    </v-container>
  </div>
</template>

<script>
import axios from "axios";

import Hero from "@/components/shared/Hero";

export default {
  metaInfo: () => ({
    title: "Abardo - Support",
  }),
  components: { Hero },
  data() {
    return {
      isSending: false,
      validForm: false,
      name: null,
      email: null,
      message: null,
      agreement: false,
      snack: {
        show: false,
        text: null,
      },
    };
  },
  mounted() {
    setTimeout(() => {
      this.$refs.name.focus();
    }, 500);
  },
  methods: {
    async send() {
      if (this.isSending) return;
      this.isSending = true;

      try {
        await axios({
          method: "put",
          url: "https://abardo-api.azurewebsites.net/email/message/external",
          data: {
            name: this.name,
            email: this.email,
            message: this.message,
          },
        });
      } catch (error) {
        this.snackbar = {
          show: true,
          message: error.response.data,
        };
      }

      this.snack = {
        show: true,
        text: "Thanks! We will get back to you as soon as possible.",
      };
      this.isSending = false;
      this.reset();
    },
    validate() {
      this.validForm = this.$refs.form.validate();
      if (this.validForm) this.send();
    },
    reset() {
      this.$refs.form.reset();
    },
  },
};
</script>

<style scoped></style>
